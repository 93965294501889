import React, { useState } from 'react'
import { composeAtomicAssetImgUrl } from 'utils/composeAtomicAssetImgUrl'

import styles from './styles.module.scss'

import NoImage from 'assets/img/no-image.svg'

type OptionsType = {
  options: any[]
  fullOptions: any[]
  isSearchAutoFocus?: boolean
  position?: 'top' | 'bottom'
  onOptionClicked: (value: number) => () => any
  handleScroll: (event: any) => void
}

const RenderOptions: React.FC<OptionsType> = ({
  options,
  fullOptions,
  position = 'bottom',
  isSearchAutoFocus = false,
  onOptionClicked,
  handleScroll,
}) => {
  const [optionsList, setOptionsList] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState('')

  const getImage = (img: any) => (img ? composeAtomicAssetImgUrl(img) : NoImage)

  const optionsToRender = optionsList.length
    ? optionsList
    : position === 'top'
    ? fullOptions
    : options

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchValue(value)
    if (value.trim() === '') {
      return setOptionsList([])
    }

    const filteredOptions = fullOptions.filter((option) => {
      return (
        option.name?.toLowerCase().includes(value.toLowerCase()) ||
        option.template_id.toLowerCase().includes(value.toLowerCase())
      )
    })

    setOptionsList(filteredOptions)
  }

  return (
    <div className={styles['ui-select__dropdown']}>
      <div onScroll={handleScroll} className={styles['ui-select__dropdown__wrapper']}>
        <div
          className={`${styles['ui-select__dropdown__option']}
            ${styles['ui-select__dropdown__search']}`}>
          <input
            autoFocus={isSearchAutoFocus}
            className={styles['ui-select__search']}
            type="text"
            value={searchValue}
            onChange={handleChange}
            placeholder="Template name"
            onFocus={(e) => e.preventDefault()}
          />
        </div>
        {optionsToRender.map(
          ({
            name,
            immutable_data,
            template_id,
          }: {
            name: any
            immutable_data: any
            template_id: any
          }) => {
            return (
              <div
                className={styles['ui-select__dropdown__option']}
                key={template_id}
                onClick={onOptionClicked(template_id)}>
                <img
                  className={styles['ui-select__dropdown__option__image']}
                  src={getImage(immutable_data && immutable_data.img)}
                  alt=""
                />
                <div className={styles['ui-select__dropdown__option__text']}>
                  {name || 'No name'} #{template_id}
                </div>
              </div>
            )
          },
        )}
      </div>
    </div>
  )
}

export default RenderOptions
