import NoImage from 'assets/img/no-image.svg'

const isImgIpfsFileHash = (img: string): boolean => {
  // The `URL` constructor will throw if the given string
  // is not correctly formatted (e.g. in the case of an IPFS file hash).
  // Thus, this can be used to understand if `img` is an HTTP URL or an
  // IPFS file hash.
  try {
    new URL(img)
    return false
  } catch (e) {
    return true
  }
}

/**
 * Composes a valid HTTP URL for an AtomicAsset image.
 *
 * If the given `img` is an IPFS file hash, it will create a URL string using it
 * and the AtomicHub IPFS gateway as a base. In all other cases, The `img` is
 * assumed to be already a valid HTTP URL and will be returned as-is.
 */
export const composeAtomicAssetImgUrl = (img: string): string => {
  const baseIpfsUrl = 'https://ipfs.io/ipfs'

  if (isImgIpfsFileHash(img)) {
    return `${baseIpfsUrl}/${img}`
  }

  return img
}

export const getImage = (img: any) => (img ? composeAtomicAssetImgUrl(img) : NoImage)
