import { generateID } from 'utils/simpleID'

import { useNotificationsStore } from './notifiesStore'

export function useNotifier() {
  const store = useNotificationsStore()

  return {
    notify: (message: string, type: 'error' | 'success') => {
      store.updateNotifiesStack(draft => {
        return [{ id: generateID(), message, type }, ...draft]
      })
    },
  }
}
