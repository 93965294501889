export const createConfirmation =
  ({ successCallback, templatesAdded, popupHandler }: any) =>
  () => {
    if (templatesAdded.length === 0) {
      popupHandler(true)
      return
    } else {
      popupHandler(false)
      successCallback()
      return
    }
  }

export function generateID() {
  return '_' + Math.random().toString(36).slice(2, 9)
}
