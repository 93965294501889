import React, { useEffect, useState } from 'react'
import { useNotificationsStore } from 'services/notifiesStore'
import styles from './ConnectedNotifies.module.scss'

export const ConnectedNotifies = () => {
  const store = useNotificationsStore()

  if (!store.notifiesStack.length) {
    return null
  }

  return (
    <div className={styles['notify']}>
      {store.notifiesStack.map((notify: any) => {
        return <NotifyBox key={notify.id} message={notify} />
      })}
    </div>
  )
}

export const NotifyBox = ({ message }: any) => {
  const [alert, setAlert] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(false)
    }, 3000)

    return () => {
      clearTimeout(timer)
      // const filteredNots = store.notifiesStack.filter((not: any) => not.id !== message.id)
      // console.log('filteredNots:', filteredNots)
      // if (filteredNots.length) {
      //   store.updateNotifiesStack(filteredNots)
      // }
      // console.log('UNMOUNT!')
    }
  }, [])

  if (!alert) return null

  return (
    <div
      className={`
				${styles['notify-item']}
					${styles[`notify-item--${message.type}`]}
				`}>
      {message.message}
      {message.submessage && <div>{message.submessage}</div>}
    </div>
  )
}
