/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Link, useMatch } from 'react-location'

import styles from './DetailView.module.scss'

import { ReactComponent as Arrow } from 'assets/img/arrow-slider.svg'
import IngredientCard from 'components/IngredientCard'
import Button from 'components/Button'
import Input from 'components/Form/Input'
import { useAssetsDataAPI } from 'services/api/assets'
import { useBlenderData } from 'application/blenderData'
import { extractOutputIds } from 'domain/blends'
import { useStore } from 'services/store'
import { useEOSIOAPI } from 'application/eosioAPI'
import { ActionContainer } from './ActionContainer'
import { ITemplate, ITemplateRecipes } from 'domain/template'

const DetailView: React.FC = () => {
  const { collection } = useMatch().params
  const [selectedRecipeId, setSelectedRecipeId] = useState<number>()
  const [blendId, setBlendId] = useState<number>()
  const [inputValue, setInputValue] = useState('')
  const [items, setItems] = useState<ITemplateRecipes[]>([])
  const [backupItems, setBackupItems] = useState<ITemplateRecipes[]>([])
  const store = useStore()
  const [showCollectionAuth, setShowCollectionAuth] = useState(false)
  const assetsAPI = useAssetsDataAPI(store.currentNetwork)
  const blenderAPI = useBlenderData()
  const { blenderData } = useBlenderData()
  const { deleteRecipe, authorizeAccount } = useEOSIOAPI()

  useEffect(() => {
    if (!store.collectionsList.length) {
      return undefined
    }
    // check collection auth keys
    const foundCollection = store.collectionsList.find((collectionItem: any) => {
      return collectionItem.collection_name === collection
    })
    if (foundCollection) {
      if (!foundCollection.authorized_accounts.includes(store.blendSmartContract)) {
        setShowCollectionAuth(true)
      } else {
        // @ts-ignore
        setShowCollectionAuth(false)
      }
    }
  }, [store.collectionsList])

  useEffect(() => {
    blenderAPI.getBlends(collection)
  }, [store.currentNetwork, collection, store.blendSmartContract])

  useEffect(() => {
    if (!blenderData) return
    const filtered = extractOutputIds(blenderData)

    ;(async () => {
      if (!filtered.length) {
        return
      }

      const templatesToShow = await assetsAPI.getTemplatesbyIds(filtered)
      const filteredItems: ITemplateRecipes[] = templatesToShow.data.data
        .filter((item: ITemplate) => item.collection.collection_name === collection)
        .map((item: ITemplate) => {
          const { recipes } = blenderData
            .find(({ id: { template_id } }) => template_id === Number(item.template_id))

          return {
            ...item,
            recipes,
          }
        })

      setItems(filteredItems)
      setBackupItems(filteredItems)
    })()
  }, [blenderData])

  const selectRecipe = (recipeId: number) => {
    setSelectedRecipeId(recipeId)
    const foundBlend = blenderData.filter((blend) => {
      return blend.recipe_ids.includes(recipeId)
    })

    if (foundBlend.length) {
      setBlendId(foundBlend[0].id.template_id)
    }
  }

  const filterItems = (value: string) => {
    if (value === '') return setItems(backupItems)
    const filtered = items.filter((template) => {
      return (
        // @ts-ignore
        template?.immutable_data.name.toLowerCase().includes(value.toLowerCase()) ||
        // @ts-ignore
        template.template_id.toLowerCase().includes(value.toLowerCase())
      )
    })
    if (filtered.length) {
      setItems(filtered)
    } else {
      setItems(backupItems)
    }
  }

  const onRemove = async () => {
    const itemIndex = items.findIndex((item: ITemplateRecipes) => {
      return item.recipes.find(({ id }) => id === selectedRecipeId as number)
    })

    const onSuccess = () => {
      const filteredItems = items[itemIndex].recipes.filter(({ id }) => {
        return id !== selectedRecipeId
      })

      setItems([
        ...items.slice(0, itemIndex),
        { ...items[itemIndex], recipes: filteredItems },
        ...items.slice(itemIndex + 1),
      ])
    }

    await deleteRecipe(
      {
        template_id: items[itemIndex].template_id,
        collection_name: collection,
      },
      selectedRecipeId as number,
      onSuccess,
    )
  }

  useEffect(() => {
    filterItems(inputValue)
  }, [inputValue])

  const authAccount = async () => {
    try {
      await authorizeAccount(collection, () => setShowCollectionAuth(false))
    } catch (e) {
      console.log('err')
    }
  }

  return (
    <>
      <div className={styles['detail-page']}>
        <div className={styles['detail-page__wrapper']}>
          <div className={styles['detail-page__back']}>
            <Link to="/">
              <Arrow />
              Back
            </Link>
          </div>

          <h1>
            Collection <span className={styles['detail-page__name']}>{collection}</span> recipes
          </h1>

          {showCollectionAuth && (
            <div className={styles['detail-page__auth-coll']}>
              Before you can interact with recipes, please authorise a wombatblends account for your
              collection first
              <Button onClick={authAccount} type="button">
                authorize
              </Button>
            </div>
          )}

          <ActionContainer
            onRemove={onRemove}
            editLinkPath={`${blendId}?recipe=${selectedRecipeId}`}
            selected={blendId as number}
            collectionName={collection}
          />

          <div className={styles['detail-page__search']}>
            <Input
              mode={'light'}
              value={inputValue}
              placeholder={'Search here'}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
          <div className={styles['detail-page__list']}>
            {items.map((template) => {
              const areMultipleRecipes = template.recipes.length > 1

              return template.recipes.map(({ id, category }, index) => {
                return (
                  <IngredientCard
                    className={id === selectedRecipeId ? styles['detail-page--active'] : ''}
                    onClick={() => selectRecipe(id)}
                    key={id}
                    img={template.immutable_data.img}
                    templateName={template.immutable_data.name}
                    templateId={template.template_id}
                    {...(areMultipleRecipes ? { recipeTitle: category || `Recipe ${index + 1}` } : {})}
                  />
                )
              })
            })}
            {!items.length && <div>Nothing found</div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailView
