import { atomicAssetsInstanceEOS, atomicAssetsInstanceWAX } from './config'

import { ITemplate } from 'domain/template'

type AtomicResponse<T> = {
  success: boolean
  data: T
}

type GetTemplatesResponse = AtomicResponse<ITemplate[]>

export function useAssetsDataAPI(network: 'WAX' | 'EOS'): any {
  const atomicInstance = network === 'WAX' ? atomicAssetsInstanceWAX : atomicAssetsInstanceEOS

  return {
    getAssets(account: string, collectionName: string) {
      return atomicInstance.get(
        `assets?collection_name=${collectionName}&owner=${account}&hide_offers=true&page=1&limit=100&order=desc&sort=asset_id`,
        {},
      )
    },

    getCollectionTemplates(
      page: number = 1,
      collectionName: string,
    ): Promise<GetTemplatesResponse> {
      return atomicInstance.get(
        `templates?collection_name=${collectionName}&has_assets=false&page=${page}&limit=700&order=desc&sort=name`,
        {},
      )
    },

    getTemplatesbyIds(ids: number[]): Promise<GetTemplatesResponse> {
      return atomicInstance.get(
        `templates?ids=${ids.join(',')}&has_assets=false&page=1&limit=700&order=desc&sort=name`,
        {},
      )
    },
    getOwnerCollections(authorizedAccount: string) {
      return atomicInstance.get(
        `collections?authorized_account=${authorizedAccount}&page=1&limit=100&order=desc&sort=collection_name`,
        {},
      )
    },
  }
}
