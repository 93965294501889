import React, { useMemo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { composeAtomicAssetImgUrl } from '../../utils/composeAtomicAssetImgUrl'

import NoImage from 'assets/img/no-image.svg'

import styles from './IngredientCard.module.scss'

interface IIngredientCard {
  img: string
  templateName: string
  templateId: string
  recipeTitle?: string
  className?: string
  onClick?: (arg: any) => void
}

const IngredientCard: React.FC<IIngredientCard> = ({
  img,
  templateName,
  templateId,
  recipeTitle,
  className = '',
  onClick = () => { },
}) => {
  const loadedImg = useMemo(() => (img ? composeAtomicAssetImgUrl(img) : NoImage), [img])

  return (
    <>
      <div onClick={onClick} className={`${styles['template-card']} ${className ? className : ''}`}>
        <div className={styles['template-card__info']}>
          <div className={styles['template-card__info-name']}>
            <span>
              {templateName}
              <b className={styles['template-card__info-name--bold']}>#{templateId}</b>
            </span>
            {recipeTitle && (
              <span>
                <b className={styles['template-card__info-name--bold']}>{recipeTitle}</b>
              </span>
            )}
          </div>
        </div>
        <div className={styles['template-card__media']}>
          <LazyLoadImage
            placeholder={<img src={NoImage} alt="" />}
            src={loadedImg}
            className={styles['template-card__media-img']}
          />
        </div>
      </div>
    </>
  )
}

export default IngredientCard
