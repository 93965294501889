import { AuthenticationService } from '../application/ports'
import scatter from '../lib/ScatterService'

export function useAuth(): AuthenticationService {
  return {
    auth(network: 'WAX' | 'EOS') {
      return network === 'WAX' ? scatter.getWAXAccount() : scatter.getEOSAccount()
    },
  }
}
