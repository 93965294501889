import { useEffect, useState } from 'react'

import Button from 'components/Button'
import Input from 'components/Form/Input'
import IngredientCard from 'components/IngredientCard'
import { Select } from 'components/Select'

import { generateID } from 'utils/helpers'

import type { ITemplate, ITemplateIngredient } from 'domain/template'

import styles from './RecipeInputTemplates.module.scss'
import { Collection } from 'types/store'

type Props = {
  collectionsList: Collection[]
  collection: string
  /**
   * templates are ingredients to complete blend
   */
  inputTemplates: ITemplateIngredient[]
  /**
   * selected ingredient to manage edit/remove
   */
  selectedIngredient: string
  updateInputTemplates: (inputTemplates: ITemplateIngredient[]) => void
  updateSelectedIngredient: (selectedIngredient: string) => void
  /**
   * func of templatesLoader
   */
  loadCollectionTemplates: (
    collectionName: string,
    setter: React.Dispatch<React.SetStateAction<ITemplate[]>>
  ) => Promise<void>
}

/**
 * ui for input/needed templates to make a blend
 */
export const RecipeInputTemplates: React.FC<Props> = ({
  collectionsList,
  collection,
  inputTemplates,
  selectedIngredient,
  updateInputTemplates,
  updateSelectedIngredient,
  loadCollectionTemplates,
}) => {
  const [selectedCollectionToLoad, setSelectedCollectionToLoad] = useState(collection)
  const [itemsInput, setItemsInput] = useState<ITemplate[]>([])
  const [templatesAmountInput, setTemplatesAmountInput] = useState(1)
  const [selectedInput, setSelectedInput] = useState(1)

  useEffect(() => {
    ;(async () => {
      await loadCollectionTemplates(selectedCollectionToLoad, setItemsInput)
    })()
  }, [selectedCollectionToLoad])

  const onAddInputTemplate = () => {
    const template =
      itemsInput.find((el: any) => el.template_id === selectedInput) as ITemplate
    const result: ITemplateIngredient[] = []

    for (let i = 0; i < templatesAmountInput; i++) {
      result.push({ ...template, subId: generateID() })
    }

    updateInputTemplates(inputTemplates.concat(result))
    setTemplatesAmountInput(1)
  }

  const pickInputCallback = (value: number) => {
    setSelectedInput(value)
  }

  return (
    <div className={styles['recipe-input__box-inputs']}>
      <h2>Input templates</h2>
      <div className={styles['recipe-input__box-inputs__actions']}>
        <Select
          callback={pickInputCallback}
          selectedIndex={selectedInput}
          supportedTokens={itemsInput}
          isSearchAutoFocus
        />
        <Input
          mode="light"
          placeholder="Search here"
          onChange={(e) => setTemplatesAmountInput(Number(e.target.value))}
          value={templatesAmountInput}
          type="text"
        />
        <Button onClick={onAddInputTemplate} type="button">
          Add
        </Button>
      </div>

      <div className={styles['recipe-input__available']}>
        available collections:{' '}
        {collectionsList.map((collection) => {
          return (
            <button
              onClick={() => setSelectedCollectionToLoad(collection.collection_name)}
              className={`${selectedCollectionToLoad === collection.collection_name
                ? styles['recipe-input__available--active']
                : ''
                }`}>
              {collection.collection_name}
            </button>
          )
        })}
      </div>
      <div className={styles['recipe-input__list']}>
        {inputTemplates.map((template) => {
          return (
            <IngredientCard
              className={
                template.template_id === selectedIngredient ? styles['recipe-input--active'] : ''
              }
              onClick={() => updateSelectedIngredient(template.template_id)}
              key={template.subId}
              img={template.immutable_data.img}
              templateName={template.name}
              templateId={template.template_id}
            />
          )
        })}
        {!inputTemplates.length && <div>Add at least one template</div>}
      </div>
    </div>
  )
}
