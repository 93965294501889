import { get_table_rows } from '../../helpers'
import { Blend, Recipe, Collection } from './types'

export async function get_blends(
  smart_contract: string, collection_name: string, template_id?: number
): Promise<Blend[]> {
  const code = smart_contract
  const scope = collection_name
  const table = 'blends'
  const lower_bound = template_id
  const upper_bound = template_id
  return get_table_rows(code, scope, table, { lower_bound, upper_bound })
}

export async function get_recipes(
  smart_contract: string, collection_name: string, id?: number
): Promise<Recipe[]> {
  const code = smart_contract
  const scope = collection_name
  const table = 'recipes'
  const lower_bound = id
  const upper_bound = id
  return get_table_rows(code, scope, table, { lower_bound, upper_bound })
}

export async function get_collections(smart_contract: string): Promise<string[]> {
  const code = smart_contract
  const scope = smart_contract
  const table = 'collections'
  return (await get_table_rows<Collection>(code, scope, table))[0].collection_names
}

