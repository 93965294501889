import { Link } from 'react-location'

import Button from 'components/Button'

import styles from './DetailView.module.scss'

type Props = {
  /**
   * selected template id
   */
  selected: number | string
  editLinkPath?: string
  collectionName: string
  /**
   * handler to remove recipe
   */
  onRemove: () => void
  /**
   * handler to create a recipe
   */
  onCreate?: () => void
  /**
   * selected version of page
   */
  pageType?: 'default' | 'new' | 'edit'
}

export const ActionContainer: React.FC<Props> = ({
  selected,
  editLinkPath,
  collectionName,
  onRemove,
  onCreate = () => {},
  pageType = 'default',
}) => {
  return (
    <div className={styles['detail-page__actions']}>
      {collectionName !== 'null' && Boolean(selected) ? (
        <Link to={`edit/${editLinkPath}`}>
          <Button bordered type={'button'}>
            Edit
          </Button>
        </Link>
      ) : (
        <div></div>
      )}
      {selected ? (
        <Button onClick={onRemove} bordered type={'button'}>
          Delete
        </Button>
      ) : (
        <div></div>
      )}
      {pageType === 'default' && (
        <Link to={'new'}>
          <Button type={'button'}>New recipe</Button>
        </Link>
      )}
      {pageType === 'new' && (
        <Button onClick={onCreate} type={'button'}>
          Create recipe
        </Button>
      )}
      {pageType === 'edit' && (
        <Button onClick={onCreate} type={'button'}>
          Edit recipe
        </Button>
      )}
    </div>
  )
}