import { useStore } from 'services/store'

export function useChangeNetworkService() {
  const store = useStore()

  function changeNetwork(network: 'EOS' | 'WAX') {
    localStorage.setItem('lastChosenNetwork', network)
    store.updateCurrentNetwork(network)
  }

  return Object.freeze({
    changeNetwork,
  })
}
