import { ConfirmationPopup } from 'components/ConfirmationPopup'
import { composeAtomicAssetImgUrl } from 'utils/composeAtomicAssetImgUrl'

import { ITemplateIngredient } from 'domain/template'

import NoImage from 'assets/img/no-image.svg'

import styles from './RecipeConfirmationPopup.module.scss'

type Props = {
  title: string
  /**
   * triggers when the popup is closing
   */
  callback: (value: boolean) => void
  /**
   * triggers on "confirm" popup
   */
  successCallback: () => void
  isOpen: boolean
  outputTemplate: { name: string; template_id: string }
  outputImage: string
  inputTemplates: ITemplateIngredient[]
  recipeCategory: string
}

/**
 * Summary of added/edited recipe show within popup
 */
export const RecipeConfirmationPopup: React.FC<Props> = ({
  title,
  callback,
  successCallback,
  isOpen,
  outputTemplate,
  recipeCategory,
  outputImage,
  inputTemplates,
}) => {
  const getImage = (img: any) => (img ? composeAtomicAssetImgUrl(img) : NoImage)

  return (
    <ConfirmationPopup
      title={title}
      callback={callback}
      successCallback={successCallback}
      open={isOpen}>
      <article>
        <div className={styles['recipe-confirmation__output-container']}>
          <p>
            The output template: {outputTemplate && outputTemplate.name} #
            {outputTemplate && outputTemplate.template_id}
          </p>
          {recipeCategory.length > 0 && (
            <p>
              Category: {recipeCategory}
            </p>
          )}
          <img
            src={outputImage}
            alt=""
          />
        </div>
        <div className={styles['recipe-confirmation__input-container']}>
          <p>Input templates: </p>
          <ul>
            {inputTemplates.map((template) => {
              return (
                <li key={template.subId} className={styles['recipe-confirmation__input-container-item']}>
                  <img src={getImage(template.immutable_data.img)} alt="" />
                  <span>
                    {template.name} #{template.template_id}
                  </span>
                </li>
              )
            })}
          </ul>
          <p>Code view:</p>
          <code>
            {JSON.stringify(
              inputTemplates.map((template: any) => {
                return {
                  collection_name: template.collection.collection_name,
                  template_id: Number(template.template_id),
                }
              }),
            )}
          </code>
        </div>
      </article>
    </ConfirmationPopup>
  )
}
