export const waxNodes = [
  { url: 'https://wax-rpc.wombat.app', history: false },
  { url: 'https://wax.cryptolions.io', history: false },
  { url: 'https://wax.greymass.com', history: false },
  { url: 'https://chain.wax.io', history: false },
  { url: 'https://wax.eu.eosamsterdam.net', history: false },
  { url: 'https://wax.eoseoul.io', history: false },
]

export const eosNodes = [
  { url: 'https://eos-rpc.wombat.app', history: false },
  { url: 'https://eos.greymass.com', history: false },
]
