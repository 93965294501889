export const extractOutputIds = (blends: any) => {
  return blends.map((blend: any) => blend.id.template_id)
}

export const extractInputIds = (blends: any) => {
  return blends.map((template: any) => template.template_id)
}

export const extractRecipeId = (blend: any) => {
  return blend.recipe_ids[0]
}
