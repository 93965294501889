import { useEffect, useState } from 'react'
import { Link, useMatch } from 'react-location'

import { useAssetsDataAPI } from 'services/api/assets'
import { useBlenderData } from 'application/blenderData'
import { useStore } from 'services/store'
import { useTemplatesLoader } from 'application/templates'
import { useEOSIOAPI } from 'application/eosioAPI'
import { useNotifier } from 'services/notificationAdapter'

import { ActionContainer } from 'views/DetailView/ActionContainer'
import Button from 'components/Button'
import { RecipeConfirmationPopup } from 'components/RecipeConfirmationPopup'
import { RecipeInputTemplates } from 'components/RecipeInputTemplates'
import { RecipeOutput } from 'components/RecipeOutput'

import { createConfirmation, generateID } from 'utils/helpers'
import * as gems from '../../lib/gems'
import { getImage } from 'utils/composeAtomicAssetImgUrl'

import type { ITemplateIngredient } from 'domain/template'

import { ReactComponent as Arrow } from 'assets/img/arrow-slider.svg'

import styles from './EditTemplateView.module.scss'

export const plugins = ['plugin1', 'plugin2', 'plugin3']

export const EditTemplateView = () => {
  const { params: { collection, id }, search: { recipe: recipeId } } = useMatch()
  const store = useStore()
  const assetsAPI = useAssetsDataAPI(store.currentNetwork)
  const blenderAPI = useBlenderData()
  const blenderData = useBlenderData()
  const templatesLoader = useTemplatesLoader()

  const { editRecipe } = useEOSIOAPI()

  const [selectedIngredient, setSelectedIngredient] = useState<string | null>(null)
  const [items, setItems] = useState([])
  const [selectedPlugin, setSelectedPlugin] = useState('')
  const [selectedOutput, setSelectedOutput] = useState(1)
  const [outputTemplate, setOutputTemplate] = useState<ITemplateIngredient | null>(null)
  const [isNeedConfirmation, setIsNeedConfirmation] = useState(false)
  const [recipeCategory, setRecipeCategory] = useState('')
  const [inputTemplates, setInputTemplates] = useState<ITemplateIngredient[]>([])
  const notifyService = useNotifier()

  useEffect(() => {
    ;(async () => {
      // load all templates for current collection
      await templatesLoader.loadCollectionTemplates(collection, setItems)
    })()
  }, [collection])

  useEffect(() => {
    blenderAPI.getBlends(collection)
  }, [store.currentNetwork, store.blendSmartContract])

  useEffect(() => {
    // find the collectionName
    const currentBlend = blenderData.blenderData.find(
      (blend: any) => blend.id.template_id === Number(id),
    )
    if (!currentBlend) {
      return
    }

    // lets find output template
    ;(async () => {
      const outputTemplate = await assetsAPI.getTemplatesbyIds([currentBlend.id.template_id])
      if (outputTemplate.data.data) {
        setOutputTemplate(outputTemplate.data.data[0])
      }

      // lets find all Recipes for collections
      const collRecipes = await gems.blend.get_recipes(
        store.blendSmartContract,
        collection,
        Number(recipeId),
      )

      // lets find recipe for our blend
      const foundRecipe = collRecipes.find((recipe) => {
        return recipe.id === recipeId
      })
      if (!foundRecipe) return undefined

      const inputTemplateIds = foundRecipe.templates?.map((template) => {
        return template.template_id
      })

      const inputTemplates = await assetsAPI.getTemplatesbyIds(inputTemplateIds)
      if (inputTemplates) {
        const inputTemplatesArr = foundRecipe.templates.map((template: any) => {
          const templateId = template.template_id
          const foundAtomicTemplate = inputTemplates.data.data.find((template: any) => {
            return template.template_id == templateId
          })
          if (foundAtomicTemplate) {
            return { ...foundAtomicTemplate, subId: generateID() }
          }
        })
        setInputTemplates(inputTemplatesArr)
        setRecipeCategory(foundRecipe.category || '')
      }
      // load all templates for current collection

      await templatesLoader.loadCollectionTemplates(collection, setItems)
    })()

  }, [store.blendSmartContract, blenderData.blenderData])

  useEffect(() => {
    const template = items.find((el: any) => el.template_id === selectedOutput)
    if (template) {
      setOutputTemplate(template)
    }
  }, [selectedOutput])

  const onRemove = (id: string) => {
    if (!id) return
    const filteredItems = inputTemplates.filter((template) => {
      return template.template_id !== id
    })

    setInputTemplates(filteredItems)
    setSelectedIngredient(null)
  }

  const editRecipeHandler = () => {
    if (recipeCategory.length === 0) {
      notifyService.notify('Recipe\'s category is a mandatory value for a recipe', 'error')
      return setIsNeedConfirmation(false)
    }

    editRecipe({
      id: { collection_name: collection, template_id: id },
      templates: inputTemplates.map((template: any) => {
        return {
          collection_name: template.collection.collection_name,
          template_id: Number(template.template_id),
        }
      }),
      category: recipeCategory,
      recipe_id: recipeId,
    })
    setIsNeedConfirmation(false)
  }

  const withConfirmation = createConfirmation({
    successCallback: () => editRecipe({}),
    templatesAdded: [],
    popupHandler: setIsNeedConfirmation,
  })

  const clearForm = () => {
    setSelectedIngredient(null)
    setSelectedPlugin('')
    setInputTemplates([])
    setRecipeCategory('')
    setOutputTemplate(null)
  }

  return (
    <div className={styles['edit-template']}>
      <div className={styles['edit-template__wrapper']}>
        <div className={styles['edit-template__back']}>
          <Link to={`/collection/${collection}`}>
            <Arrow />
            Back
          </Link>
        </div>

        <h1>Edit recipe</h1>

        <Button
          onClick={clearForm}
          className={styles['edit-template__clear-btn']}
          bordered
          type="button">
          clear form
        </Button>

        <ActionContainer
          onRemove={() => onRemove(selectedIngredient as string)}
          onCreate={() => withConfirmation()}
          collectionName={'null'}
          selected={selectedIngredient as string}
          pageType={'edit'}
        />

        <div className={styles['edit-template__box']}>
          <RecipeConfirmationPopup
            title='Edit confirmation'
            callback={setIsNeedConfirmation}
            successCallback={editRecipeHandler}
            isOpen={isNeedConfirmation}
            inputTemplates={inputTemplates}
            outputImage={getImage(outputTemplate && outputTemplate.immutable_data['img']) || ''}
            outputTemplate={outputTemplate as ITemplateIngredient}
            recipeCategory={recipeCategory}
          />

          <RecipeOutput
            category={recipeCategory}
            updateCategory={setRecipeCategory}
            outputTemplate={outputTemplate}
            selectedOutput={selectedOutput}
            plugins={plugins}
            selectedPlugin={selectedPlugin}
            updatePlugin={setSelectedPlugin}
            updateSelectedOutput={setSelectedOutput}
          />
          <RecipeInputTemplates
            collectionsList={store.collectionsList}
            collection={collection}
            selectedIngredient={selectedIngredient as string}
            inputTemplates={inputTemplates}
            updateSelectedIngredient={setSelectedIngredient}
            updateInputTemplates={setInputTemplates}
            loadCollectionTemplates={templatesLoader.loadCollectionTemplates}
          />
        </div>
      </div>
    </div>
  )
}
