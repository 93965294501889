import React, { useEffect } from 'react'
import { Router, Outlet } from 'react-location'
import { routes, location } from './Routes'

import { Header } from 'components/Header'
import { useAuthenticate } from 'application/authenticate'
import { useAssetsDataAPI } from 'services/api/assets'
import { useStore } from 'services/store'
import { ConnectedNotifies } from 'components/ConnectedNotifies'

function App() {
  const { userAccount, authenticate } = useAuthenticate()
  const store = useStore()
  const assetsApi = useAssetsDataAPI(store.currentNetwork)

  useEffect(() => {
    ;(async () => {
      await authenticate(store.currentNetwork)
      console.log('store.currentNetwork:', store.currentNetwork)
    })()
  }, [userAccount, store.currentNetwork])

  useEffect(() => {
    ;(async () => {
      const collections = await assetsApi.getOwnerCollections(store.blendSmartContract)

      if (collections.data.data.length > 0) {
        store.updateCollectionsList(collections.data.data)
      }
    })()
  }, [userAccount, store.currentNetwork, store.blendSmartContract])

  return (
    <div className="App">
      <Header />
      <ConnectedNotifies />
      <Router routes={routes} location={location}>
        <Outlet />
      </Router>
    </div>
  )
}

export default App
