import axios from 'axios'
import rateLimit from 'axios-rate-limit'

// Instance to get collections, schemas, templates
export const atomicAssetsInstanceWAX = rateLimit(
  axios.create({
    baseURL: 'https://wax-aa.wombat.app/atomicassets/v1/',
  }),
  { maxRPS: 20 },
)

// Instance to get collections, schemas, templates
export const atomicAssetsInstanceEOS = axios.create({
  baseURL: 'https://eos-aa.wombat.app/atomicassets/v1/',
})

export const CHAIN_API_PATH = '/v1/chain'
