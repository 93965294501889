import ScatterJS from '@scatterjs/core'
import ScatterEOS from '@scatterjs/eosjs2'
import ScatterLynx from '@scatterjs/lynx'
import { Api, JsonRpc } from 'eosjs'
import { waxNodes, eosNodes } from 'services/api/nodes'

import { IScatterAccount } from 'types/scatter'

const getFastestNode = async (nodesArray: any) => {
  return Promise.any(
    nodesArray.map(({ url }: any) => {
      return fetch(`${url}/v1/chain/get_info`).then((response) => [
        response.json(),
        url.split('//')[1],
      ])
    }),
  )
}

class Scatter {
  private fastestNodeWAX = 'wax.cryptolions.io' //wax.greymass.com'
  private fastestNodeEOS = 'eos.greymass.com'
  private network = ScatterJS.Network.fromJson({
    blockchain: 'eos',
    chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
    host: this.fastestNodeWAX,
    port: 443,
    protocol: 'https',
  })

  private network2 = ScatterJS.Network.fromJson({
    blockchain: 'eos',
    chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
    host: this.fastestNodeEOS,
    port: 443,
    protocol: 'https',
  })

  private API?: Api | undefined
  private API_EOS?: Api | undefined
  account: IScatterAccount = {
    authority: 'null',
    blockchain: 'null',
    chainId: 'null',
    isHardware: false,
    name: '',
    publicKey: '',
  }

  constructor() {
    ScatterJS.plugins(new ScatterEOS(), new ScatterLynx({ Api, JsonRpc }))
  }

  async getEOSAccount() {
    try {
      const [, fastNode]: any = await getFastestNode(eosNodes)
      if (fastNode) {
        console.log('fastest node:', fastNode)
        this.fastestNodeEOS = fastNode
        this.network.host = fastNode
      }
    } catch (e) {
      console.log('Cant connect to nodes')
    }
    //console.log('try eOS')
    const connected = await ScatterJS.connect(process.env.REACT_APP_DAPP_NAME + '_eos', {
      network: this.network2,
      linkTimeout: 5000,
    })
    if (!connected) {
      throw new Error("Can't connect to ScatterJS")
    }
    const identity = await ScatterJS.getIdentity({ accounts: [this.network2] })

    this.account = identity?.accounts.find(
      (account: IScatterAccount) => account.blockchain === 'eos',
    )

    if (!this.account) {
      throw new Error("Can't connect to ScatterJS (There is not identity EOS)")
    }

    this.API_EOS = ScatterJS.eos(this.network2, Api, {
      rpc: new JsonRpc(this.network2.fullhost()),
      beta3: true,
    })

    return this.account.name
  }

  async getWAXAccount() {
    //console.log('try WAX')
    try {
      const [, fastNode]: any = await getFastestNode(waxNodes)
      if (fastNode) {
        console.log('fastest node:', fastNode)
        this.fastestNodeWAX = fastNode
        this.network.host = fastNode
      }
    } catch (e) {
      console.log('Cant connect to nodes')
    }

    const connected = await ScatterJS.connect(process.env.REACT_APP_DAPP_NAME + '_wax', {
      network: this.network,
      linkTimeout: 6000,
    })
    if (!connected) {
      throw new Error("Can't connect to ScatterJS")
    }

    const identity = await ScatterJS.getIdentity({ accounts: [this.network] })

    this.account = identity?.accounts.find(
      (account: IScatterAccount) => account.blockchain === 'eos',
    )

    if (!this.account) {
      throw new Error("Can't connect to ScatterJS (There is not identity WAX)")
    }

    this.API = ScatterJS.eos(this.network, Api, {
      rpc: new JsonRpc(this.network.fullhost()),
      beta3: true,
    })

    return this.account.name
  }

  async setup(): Promise<boolean> {
    if (this.API) return true
    await this.getWAXAccount()
    return true
  }

  async transact(
    actions: {
      name: string
      account: string
      data: any
    }[],
  ) {
    if (!this.API_EOS) {
      await this.setup()
    }

    if (ScatterJS.identity.name !== this.account?.name) {
      await this.getEOSAccount()
    }
    const authorizedActions = actions.map((action) => ({
      ...action,
      authorization: [
        {
          actor: this.account?.name,
          permission: 'active',
        },
      ],
    }))
    console.log('DATA TO SIGN:', authorizedActions)
    return this.API_EOS?.transact(
      { actions: authorizedActions },
      { expireSeconds: 30, blocksBehind: 3 },
    )
  }

  async transactWAX(
    actions: {
      name: string
      account: string
      data: any
    }[],
  ) {
    if (!this.API) {
      await this.setup()
    }

    if (ScatterJS.identity.name !== this.account?.name) {
      await this.getWAXAccount()
    }

    const authorizedActions = actions.map((action) => ({
      ...action,
      authorization: [
        {
          actor: this.account?.name,
          permission: 'active',
        },
      ],
    }))
    console.log('DATA TO SIGN:', authorizedActions)
    return this.API?.transact(
      { actions: authorizedActions },
      { expireSeconds: 30, blocksBehind: 3 },
    )
  }
}

export default new Scatter()
