import { useAuth } from 'services/authAdapter'
import { useUserAccountStorage } from 'services/storageAdapter'
import { AuthenticationService, UserAccountStorageService } from './ports'

export function useAuthenticate() {
  const storage: UserAccountStorageService = useUserAccountStorage()
  const auth: AuthenticationService = useAuth()

  //console.log('storage', storage)

  async function authenticate(network: 'WAX' | 'EOS'): Promise<void> {
    const user = await auth.auth(network)
    console.log('user:', user)
    storage.updateUserAccount(user)
  }

  return Object.freeze({
    userAccount: storage.userAccount,
    authenticate,
  })
}
