import React, { createContext, useContext, useEffect, useState } from 'react'

import { Collection } from 'types/store'

type Store = {
  /**
   * The name of the smart contract storing recipes and blends
   */
  blendSmartContract: string
  account: any
  collectionsList: Collection[]
  blenderData: any
  currentNetwork: any
  updateCurrentNetwork: any
  updateBlenderData: any
  updateCollectionsList: any
  updateAccount: any
  /**
   * Update the smart contract to be managed
   * @param newSmartContract The name of the new smart contract to manage
   */
  updateBlenderSmartContract: (newSmartContract: string) => void
}

// @ts-ignore
const StoreContext = createContext<Store>({})
export const useStore = () => useContext(StoreContext)

export const initialUIStore = {
  currentOpenCollection: null,
  isGlobalPreloader: true,
  isFilterMode: false,
}

export type UIStore = typeof initialUIStore

const BLEND_SMART_CONTRACT_EOS = process.env.REACT_APP_BLEND_SMART_CONTRACT_EOS || 'wombatblends'
const BLEND_SMART_CONTRACT_WAX = process.env.REACT_APP_BLEND_SMART_CONTRACT_WAX || 'wombatblends'

function getDefaultBlendSmartContract(network: string) {
  if (network === 'EOS') {
    return BLEND_SMART_CONTRACT_EOS
  } else {
    return BLEND_SMART_CONTRACT_WAX
  }
}

export const Provider: React.FC = ({ children }) => {
  const lastChosenNetwork = localStorage.getItem('lastChosenNetwork')
  const [account, setAccount] = useState(null)
  const [collectionsList, setCollectionsList] = useState([])
  const [blenderData, setBlenderData] = useState([])

  const [currentNetwork, setCurrentNetwork] = useState(
    lastChosenNetwork === null ? 'EOS' : lastChosenNetwork,
  )

  const [blendSmartContract, setBlendSmartContract] = useState(
    () => getDefaultBlendSmartContract(currentNetwork),
  )

  // When the chain changes, use the default smart contract for that chain
  useEffect(() => {
    const smartContract = getDefaultBlendSmartContract(currentNetwork)
    setBlendSmartContract(smartContract)
  }, [currentNetwork])

  const value = {
    account,
    collectionsList,
    blenderData,
    currentNetwork,
    blendSmartContract,
    updateCurrentNetwork: setCurrentNetwork,
    updateBlenderData: setBlenderData,
    updateCollectionsList: setCollectionsList,
    updateAccount: setAccount,
    updateBlenderSmartContract: setBlendSmartContract,
  }

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}
