import React from 'react'

import IButton from './ButtonTypes'

import styles from './Button.module.scss'

const Button: React.FC<IButton> = (props) => {
  const {
    icon,
    children,
    dark = false,
    bordered = false,
    className = '',
    onClick,
    color = '',
    disabled = false,
    onClickDisabled,
  } = props

  if (onClickDisabled) {
    return (
      <div className={styles['btn__disabled-click-wrapper']}>
        {disabled ? (
          <button
            type="button"
            className={styles['btn__disabled-click']}
            onClick={onClickDisabled}
          />
        ) : null}
        <button
          type={props.type}
          onClick={() => {
            if (!disabled && onClick) {
              onClick()
            }
          }}
          className={`
              ${styles.btn}
              ${bordered ? styles['btn_bordered'] : ''}
              ${className}
              ${color ? styles[`btn_${color}`] : ''}
              ${dark ? styles['btn_dark'] : ''}
            `}
          disabled={disabled}>
          {icon ? <span className={styles['btn__icon']}>{icon}</span> : null}
          {children}
        </button>
      </div>
    )
  }

  switch (props.link) {
    case undefined:
      return (
        <button
          type={props.type}
          onClick={() => {
            if (!disabled && onClick) {
              onClick()
            }
          }}
          className={`
              ${styles.btn}
              ${bordered ? styles['btn_bordered'] : ''}
              ${className}
              ${color ? styles[`btn_${color}`] : ''}
              ${dark ? styles['btn_dark'] : ''}
            `}
          disabled={disabled}>
          {icon ? <span className={styles['btn__icon']}>{icon}</span> : null}
          {children}
        </button>
      )

    default:
      return (
        <a
          href={props.link}
          onClick={onClick}
          target="_blank"
          rel="noreferrer"
          className={`
            ${styles.btn}
              ${bordered ? styles['btn_bordered'] : ''}
              ${className}
              ${styles[`btn_${color}`]}
              ${dark ? styles['btn_dark'] : ''}
            `}>
          {icon ? <span className={styles['btn__icon']}>{icon}</span> : null}
          {children}
        </a>
      )
  }
}

export default Button
