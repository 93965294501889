import { useEffect, useState } from 'react'
import { Link, useMatch } from 'react-location'

import { useStore } from 'services/store'
import { useTemplatesLoader } from 'application/templates'
import { useEOSIOAPI } from 'application/eosioAPI'
import { useNotifier } from 'services/notificationAdapter'

import { ActionContainer } from 'views/DetailView/ActionContainer'
import { RecipeConfirmationPopup } from 'components/RecipeConfirmationPopup'
import { RecipeInputTemplates } from 'components/RecipeInputTemplates'
import { RecipeOutput } from 'components/RecipeOutput'
import Button from 'components/Button'

import { createConfirmation } from 'utils/helpers'

import { getImage } from 'utils/composeAtomicAssetImgUrl'
import { plugins } from 'views/EditTemplateView'

import type { ITemplateIngredient } from 'domain/template'

import { ReactComponent as Arrow } from 'assets/img/arrow-slider.svg'

import styles from './NewTemplateView.module.scss'

export const NewTemplateView = () => {
  const { collection } = useMatch().params
  const [selectedIngredient, setSelectedIngredient] = useState<string | null>(null)
  const [items, setItems] = useState([])
  const [selectedPlugin, setSelectedPlugin] = useState('')
  const [selectedOutput, setSelectedOutput] = useState(1)
  const [outputTemplate, setOutputTemplate] = useState<ITemplateIngredient | null>(null)
  const [isNeedConfirmation, setIsNeedConfirmation] = useState(false)
  const [recipeCategory, setRecipeCategory] = useState('')
  const [blendDescription, setBlendDescription] = useState('')
  const [inputTemplates, setInputTemplates] = useState<ITemplateIngredient[]>([])
  const store = useStore()
  const templatesLoader = useTemplatesLoader()
  const { createRecipe } = useEOSIOAPI()
  const notifyService = useNotifier()

  useEffect(() => {
    // load all templates for current collection
    templatesLoader.loadCollectionTemplates(collection, setItems)
      .catch(console.error)
  }, [collection])

  useEffect(() => {
    const template = items.find((el: any) => el.template_id === selectedOutput)
    if (template) {
      setOutputTemplate(template)
    }
  }, [selectedOutput])

  const onRemove = (id: string) => {
    if (!id) return
    const filteredItems = inputTemplates.filter((template) => {
      return template.template_id != id
    })

    setInputTemplates(filteredItems)
    setSelectedIngredient(null)
  }

  const createRecipeHandler = () => {
    if (recipeCategory.length === 0) {
      notifyService.notify('Recipe\'s category is a mandatory value for a recipe', 'error')
      return setIsNeedConfirmation(false)
    }

    createRecipe(
      {
        id: { collection_name: collection, template_id: Number(selectedOutput) },
        templates: inputTemplates.map((template) => {
          return {
            collection_name: template.collection.collection_name,
            template_id: Number(template.template_id),
          }
        }),
        category: recipeCategory,
      },
      {
        collection_name: collection,
        template_id: Number(selectedOutput),
        description: blendDescription,
        category: recipeCategory,
      },
    )
    setIsNeedConfirmation(false)
  }

  const withConfirmation = createConfirmation({
    successCallback: () => createRecipe({}, {}),
    templatesAdded: [],
    popupHandler: setIsNeedConfirmation,
  })

  const clearForm = () => {
    setSelectedIngredient(null)
    setSelectedPlugin('')
    setInputTemplates([])
    setOutputTemplate(null)
    setBlendDescription('')
  }

  return (
    <div className={styles['new-template']}>
      <div className={styles['new-template__wrapper']}>
        <div className={styles['new-template__back']}>
          <Link to={`/collection/${collection}`}>
            <Arrow />
            Back
          </Link>
        </div>

        <h1>Create new recipe</h1>

        <Button
          onClick={clearForm}
          className={styles['new-template__clear-btn']}
          bordered
          type="button">
          clear form
        </Button>

        <ActionContainer
          onRemove={() => onRemove(selectedIngredient as string)}
          onCreate={() => withConfirmation()}
          collectionName={'null'}
          selected={selectedIngredient as string}
          pageType='new'
        />

        <div className={styles['new-template__box']}>
          <RecipeConfirmationPopup
            title='Confirmation of creation'
            callback={setIsNeedConfirmation}
            successCallback={createRecipeHandler}
            isOpen={isNeedConfirmation}
            inputTemplates={inputTemplates}
            outputImage={getImage(outputTemplate && outputTemplate.immutable_data['img']) || ''}
            outputTemplate={outputTemplate as ITemplateIngredient}
            recipeCategory={recipeCategory}
          />

          <RecipeOutput
            category={recipeCategory}
            updateCategory={setRecipeCategory}
            outputTemplate={outputTemplate}
            selectedOutput={selectedOutput}
            plugins={plugins}
            selectedPlugin={selectedPlugin}
            updatePlugin={setSelectedPlugin}
            updateSelectedOutput={setSelectedOutput}
            description={blendDescription}
            updateDescription={setBlendDescription}
            selectSupportedTokens={items}
          />
          <RecipeInputTemplates
            collectionsList={store.collectionsList}
            collection={collection}
            selectedIngredient={selectedIngredient as string}
            inputTemplates={inputTemplates}
            updateSelectedIngredient={setSelectedIngredient}
            updateInputTemplates={setInputTemplates}
            loadCollectionTemplates={templatesLoader.loadCollectionTemplates}
          />
        </div>
      </div>
    </div>
  )
}
