import { ROUTES } from 'constants/routes'
import { Route, ReactLocation } from 'react-location'
import DetailView from 'views/DetailView'
import { EditTemplateView } from 'views/EditTemplateView'
import { NewTemplateView } from 'views/NewTemplateView'

import { NotFound } from 'views/NotFound'
import MainView from './views/MainView'
import { OverrideSettingsView } from './views/OverrideSettingsView'

export const location = new ReactLocation()

export const routes: Route[] = [
  {
    path: ROUTES.main,
    element: <MainView />,
  },
  {
    path: ROUTES.collection,

    children: [
      {
        path: '/',
        element: <DetailView />,
      },
      {
        path: '/new',
        element: <NewTemplateView />,
      },
      {
        path: '/edit/:id',
        element: <EditTemplateView />,
      },
    ],
  },
  {
    path: '/override-settings',
    element: <OverrideSettingsView />,
  },
  {
    element: <NotFound />,
  },
]
