import { useStore } from 'services/store'
import scatterService from '../lib/ScatterService'

import * as gems from '../lib/gems'
import { setblend } from 'lib/gems/src/gems/blend/actions'
import { useNotifier } from 'services/notificationAdapter'

export function useEOSIOAPI() {
  const store = useStore()
  const notifyService = useNotifier()

  // USER INPUT
  //main().catch((e) => console.error(e))

  const requestBuilder = (data: any) => {
    switch (store.currentNetwork) {
      case 'EOS': {
        return scatterService.transact(data)
      }
      case 'WAX': {
        return scatterService.transactWAX(data)
      }
      default: {
        return scatterService.transact(data)
      }
    }
  }

  async function authorizeAccount(collectionName: string, cb: any) {
    const request = requestBuilder([
      {
        name: 'addcolauth',
        account: 'atomicassets',
        data: {
          collection_name: collectionName,
          account_to_add: store.blendSmartContract,
        },
      },
    ])

    // @ts-ignore
    request
      .then((response: any) => {
        //console.log('success response?', response)
        //alert(JSON.stringify(response))
        notifyService.notify('Collection was authorized. Lets create some blends!', 'success')
        cb()
        // @ts-ignore
      })
      .catch((error: any) => {
        console.log('erorr:', error)
        notifyService.notify(error.message, 'error')
      })
      .finally(() => {
        //console.log('finally')
      })
  }

  async function deleteRecipe(
    id: { collection_name: string; template_id: string },
    recipe_id: number,
    cb: () => void,
  ) {
    const request = requestBuilder([
      {
        name: 'delrecipe',
        account: store.blendSmartContract,
        data: { id, recipe_id, author: store.account },
      },
    ])

    // @ts-ignore
    request
      .then((response: any) => {
        //console.log('success response?', response)
        //alert(JSON.stringify(response))
        notifyService.notify('Blend was deleted', 'success')
        cb()
        // @ts-ignore
      })
      .catch((error: any) => {
        console.log('erorr:', error)
        notifyService.notify(error.message, 'error')
      })
      .finally(() => {
        //console.log('finally')
      })
  }

  async function editRecipe(recipe: any) {
    // before we can create a recipe
    // we need to be sure that
    // blend exists
    const blends = await gems.blend.get_blends(store.blendSmartContract, 'memewarsreal')

    const foundBlend = blends.filter((blend: any) => {
      return blend.id.template_id === recipe.id.template_id
    })

    if (!foundBlend.length) {
      // return alert('Please, first create a blend or wait a bit more (1 min)')
    }

    const request = requestBuilder([
      {
        name: 'delrecipe',
        account: store.blendSmartContract,
        data: {
          author: store.account,
          id: recipe.id,
          recipe_id: recipe.recipe_id,
        },
      },
      {
        name: 'addrecipe',
        account: store.blendSmartContract,
        data: {
          author: store.account,
          id: recipe.id,
          templates: recipe.templates,
          category: recipe.category,
        },
      },
    ])
    //
    request
      .then((response) => {
        console.log('success response?', response)
        notifyService.notify('Blend was edited', 'success')
        // @ts-ignore
      })
      .catch((error) => {
        notifyService.notify(error.message, 'error')
      })
      .finally(() => {
        //console.log('finally')
      })
  }

  async function createBlend(data: any) {
    const { collection_name, template_id, description } = data
    const raw = await setblend({
      id: { collection_name, template_id },
      description,
      start_time: new Date(),
      end_time: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
    })

    // @ts-ignore
    raw.author = store.account

    const request = requestBuilder([
      {
        name: 'setblend',
        account: store.blendSmartContract,
        data: raw,
      },
    ])

    // @ts-ignore
    request
      .then((response: any) => {
        console.log('success response?', response)
        notifyService.notify('Blend was created', 'success')
        // @ts-ignore
      })
      .catch((error: any) => {
        notifyService.notify(error.message, 'error')
      })
      .finally(() => {
        //console.log('finally')
      })
  }

  async function createRecipe(recipe: any, data: any) {
    const { collection_name, template_id, description } = data
    const raw = await setblend({
      id: { collection_name, template_id },
      description,
      start_time: new Date(),
      end_time: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
    })

    // before we can create a recipe
    // we need to be sure that
    // blend exists
    const blends = await gems.blend.get_blends(store.blendSmartContract, data.collection_name)

    const foundBlend = blends.filter((blend: any) => {
      return blend.id.template_id === recipe.id.template_id
    })

    //notifyService.notify('found blend', 'success')

    if (!foundBlend.length) {
      // return alert('Please, first create a blend or wait a bit more (1 min)')
    }
    // @ts-ignore
    raw.author = store.account

    const request = requestBuilder([
      {
        name: 'setblend',
        account: store.blendSmartContract,
        data: raw,
      },
      {
        name: 'addrecipe',
        account: store.blendSmartContract,
        data: {
          author: store.account,
          id: recipe.id,
          templates: recipe.templates,
          category: recipe.category,
        },
      },
    ])
    //
    request
      .then((response) => {
        notifyService.notify('Blend was created', 'success')
        // @ts-ignore
      })
      .catch((error) => {
        notifyService.notify(error.message, 'error')
      })
      .finally(() => {
        //console.log('finally')
      })
  }

  return Object.freeze({
    deleteRecipe,
    editRecipe,
    createRecipe,
    createBlend,
    authorizeAccount,
  })
}
