import { JsonRpc } from 'eosjs'
import fetch from 'isomorphic-fetch'

// EOSIO endpoints
// const lastChosenNetwork = localStorage.getItem('lastChosenNetwork')
// export const EOSIO_CHAIN_ID =
//   lastChosenNetwork === 'EOS'
//     ? 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906'
//     : '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4'
// export const EOSIO_RPC =
//   lastChosenNetwork === 'EOS' ? 'https://eos.greymass.com' : 'https://wax.greymass.com'
//export const rpc = new JsonRpc(EOSIO_RPC, { fetch })
export const createRPC = (EOSIO_RPC: string) => new JsonRpc(EOSIO_RPC, { fetch })
