import { Blend, Recipe } from 'lib/gems/src/gems/blend'
import { useStore } from 'services/store'
import { apiCall } from 'utils/networking'
import { CHAIN_API_PATH } from './config'

type Body = {
  code: string
  lowerBound: string
  collection: string
  table: 'blends' | 'recipes'
}

type Response<T> = {
  next_key: string
  more: boolean
  rows: T
}

const getBody = ({ code, lowerBound, collection, table }: Body) => ({
  code,
  table,
  index_position: 0,
  json: true,
  key_type: '',
  limit: 1000,
  lower_bound: lowerBound,
  reverse: false,
  scope: collection,
  show_payer: false,
  table_key: '',
  upper_bound: '',
})

export function useBlenderDataAPI() {
  const store = useStore()
  const host =
    store.currentNetwork === 'WAX' ? 'wax.cryptolions.io' : 'eos.greymass.com'
  return {
    getBlends(
      lowerBound: string,
      collection: string = '',
    ): Promise<Response<Blend[]>> {
      return apiCall(`https://${host}${CHAIN_API_PATH}/get_table_rows`, {
        method: 'POST',
        body: JSON.stringify(
          getBody({
            lowerBound,
            collection,
            table: 'blends',
            code: store.blendSmartContract,
          }),
        ),
      })
    },
    getRecipes(
      lowerBound: string,
      collection: string = '',
    ): Promise<Response<Recipe[]>> {
      return apiCall(`https://${host}${CHAIN_API_PATH}/get_table_rows`, {
        method: 'POST',
        body: JSON.stringify(
          getBody({
            lowerBound,
            collection,
            table: 'recipes',
            code: store.blendSmartContract,
          }),
        ),
      })
    },
  }
}
