import { Link } from 'react-location'
import { composeAtomicAssetImgUrl } from 'utils/composeAtomicAssetImgUrl'
import styles from './CollectionCard.module.scss'

import NoImage from 'assets/img/no-image.svg'

export const CollectionCard = ({ card }: any) => {
  const getImage = (img: any) => (img ? composeAtomicAssetImgUrl(img) : NoImage)
  return (
    <Link className={styles['collection-card']} to={`/collection/${card.collection_name}`}>
      <div className={styles['collection-card__logo']}>
        <img src={getImage(card.img)} alt="" />
      </div>
      <div className={styles['collection-card__name']}>
        {card.data.name}
        <span className={styles['collection-card__count']}>
          {card?.data.description &&
            card?.data.description.length > 80 &&
            card.data.description.slice(0, 80) + '...'}
          {card?.data.description && card?.data.description.length < 80 && card.data.description}
        </span>
      </div>
    </Link>
  )
}
