import React, { useState } from 'react'
import { Link } from 'react-location'
import { useStore } from '../../services/store'

/**
 * An "internal" view to override settings of the app
 */
export const OverrideSettingsView: React.VFC = () => {
  const { blendSmartContract, updateBlenderSmartContract } = useStore()

  const [smartContract, setSmartContract] = useState(blendSmartContract)

  return <main>
    <h1>Override settings</h1>

    <Link to="/">
      <h2>Go back</h2>
    </Link>

    <p>
      Note: navigate via "Go back" after changing settings here for them to have an effect
    </p>

    <h2>Smart contract</h2>
    <p>Override the blending smart contract.</p>
    <form onSubmit={e => {
      e.preventDefault()
      updateBlenderSmartContract(smartContract)
    }}>
      <input value={smartContract} onChange={e => setSmartContract(e.currentTarget.value)} />
      <button type="submit">Change smart contract</button>
    </form>
  </main>
}
