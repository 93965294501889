/* eslint-disable react-hooks/exhaustive-deps */
import { CollectionCard } from 'components/CollectionCard'
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-location'
import { useStore } from 'services/store'

import styles from './MainView.module.scss'

const MainView: React.FC = () => {
  const store = useStore()

  return (
    <>
      <div className={styles['main-view']}>
        <h1>Show recipes</h1>
        <h2>Choose collection:</h2>
        <div className={styles['main-view__collection-list']}>
          {store.collectionsList.map((card: any) => {
            return <CollectionCard key={card.name} card={card} />
          })}
        </div>
      </div>
    </>
  )
}

export default MainView
