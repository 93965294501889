/**
 *
 */
export enum HTTPErrorKind {
  INFORMATION = 100,
  SUCCESS = 200,
  REDIRECT = 300,
  CLIENT = 400,
  SERVER = 500,
}

/**
 *
 */
function determineKind(status: number): HTTPErrorKind {
  if (status >= 100 && status < 200) return HTTPErrorKind.INFORMATION
  else if (status < 300) return HTTPErrorKind.SUCCESS
  else if (status < 400) return HTTPErrorKind.REDIRECT
  else if (status < 500) return HTTPErrorKind.CLIENT
  else if (status < 600) return HTTPErrorKind.SERVER
  else throw new Error(`Unknown HTTP status code ${status}`)
}

/** @param {HTTPErrorKind} kind */
export default class HTTPError extends Error {
  public readonly kind: HTTPErrorKind
  /**
   *
   */
  constructor(info: Response, message: string) {
    super(`HTTPError [status: ${info.statusText} (${info.status})]\n${message}`)
    this.kind = determineKind(info.status)
  }
}
