import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import { Provider } from './services/store'

import 'assets/styles/main.scss'
import { NotificationsProvider } from 'services/notifiesStore'

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <NotificationsProvider>
        <App />
      </NotificationsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
