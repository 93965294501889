import React, { useRef } from 'react'

import styles from './Input.module.scss'

interface IInput {
  type?:
    | 'button'
    | 'checkbox'
    | 'textarea'
    | 'file'
    | 'hidden'
    | 'image'
    | 'password'
    | 'radio'
    | 'reset'
    | 'submit'
    | 'color'
    | 'date'
    | 'datetime'
    | 'datetime'
    | 'email'
    | 'number'
    | 'range'
    | 'search'
    | 'tel'
    | 'time'
    | 'url'
    | 'month'
    | 'week'
    | 'text'
  label?: string
  placeholder?: string
  mode?: string
  value: string | number
  onChange: (e: any) => void
  onKeyDown?: (e: any) => void
  onWheel?: (e: any) => void
  error?: boolean
  inputRef?: any
}

const Input: React.FC<IInput> = ({
  type = 'text',
  label = '',
  placeholder = '',
  value,
  mode,
  onChange,
  onKeyDown = () => {},
  onWheel = () => {},
  error = false,
  inputRef = null,
}) => {
  const labelRef = useRef(null)

  if (type === 'textarea') {
    return (
      <div
        className={`
        ${styles['input-wrapper']}
        ${mode === 'light' ? styles['input-wrapper--light'] : ''}
      `}>
        <textarea
          placeholder={placeholder}
          value={value}
          ref={inputRef}
          rows={1}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onWheel={onWheel}
          className={`
              ${mode === 'light' ? styles['input--light'] : ''}
              ${styles['input']}
              ${error ? styles['input_error'] : ''}
            `}
        />
        {label ? (
          <div ref={labelRef} className={styles['input__label']}>
            {label}
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <div
      className={`
      ${styles['input-wrapper']}
      ${mode === 'light' ? styles['input-wrapper--light'] : ''}
    `}>
      <input
        ref={inputRef}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onWheel={onWheel}
        className={`
            ${mode === 'light' ? styles['input--light'] : ''}
            ${styles['input']}
            ${error ? styles['input_error'] : ''}
          `}
        style={{
          // @ts-ignore
          paddingRight: `${labelRef.current ? labelRef.current?.clientWidth + 24 : 16}px`,
        }}
      />
      {label ? (
        <div ref={labelRef} className={styles['input__label']}>
          {label}
        </div>
      ) : null}
    </div>
  )
}

export default Input
