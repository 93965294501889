import { useBlenderDataAPI } from '../services/api/blednerDataAPI'
import { useBlenderDataStorage } from '../services/storageAdapter'

import { BlenderDataStorageService } from './ports'

type BlenderData = {
  /**
   * additional description of a recipe
   * that can be set via create/edit recipe
   */
  description: string
  id: {
    collection_name: string
    template_id: number
  }
  /**
   * all ids of recipes for output
   */
  recipe_ids: number[]
  /**
   * recipe info to get an output
   */
  recipes: {
    id: number
    /**
     * ingredients to blend output
     */
    templatesIds: number[]
    /**
     * category/name of a recipe. If an output has a few recipes,
     * users easier to find a recipe that look for.
     */
    category?: string
  }[]
}

export function useBlenderData() {
  const blenderDataStorage: BlenderDataStorageService = useBlenderDataStorage()
  const blenderDataAPI = useBlenderDataAPI()

  async function getBlends(collection: string = '') {
    let isFetching = true
    let blendsKey = '0'
    let recipesKey = '0'
    let tempResult: Record<string, BlenderData> = {}

    while (isFetching) {
      let areBlendsWithEmptyRecipes = false

      const [blends, recipes] = await Promise.all([
        blenderDataAPI.getBlends(blendsKey, collection),
        blenderDataAPI.getRecipes(recipesKey, collection),
      ])

      blends.rows.forEach((blend) => {
        const blendRecipes = recipes.rows.filter((recipe) =>
          blend.recipe_ids.includes(recipe.id),
        )

        // A blend can have a few recipes. The more blends creates,
        // the much more recipes added. It means that all recipes can't be exist
        // in one request.
        // This condition checks it, if it's true, "while" will make additional
        // iteration to get recipes for blends.
        if (blendRecipes.length === 0 && blend.recipe_ids.length > 0) {
          areBlendsWithEmptyRecipes = true
        } else {
          const formattedBlendRecipes = blendRecipes.map(
            ({ id, templates, category }) => ({
              category: category || '',
              id,
              templatesIds: templates.map((template) => template.template_id),
            }),
          )

          tempResult[blend.id.template_id] = {
            ...blend,
            recipes: tempResult[`${blend.id.template_id}`]
              ? [
                  ...tempResult[blend.id.template_id].recipes,
                  ...formattedBlendRecipes,
                ]
              : formattedBlendRecipes,
          }
        }
      })

      if (areBlendsWithEmptyRecipes && recipes.more) {
        recipesKey = recipes.next_key || '0'
      } else {
        blendsKey = blends.next_key || '0'
        isFetching = blends.more || (areBlendsWithEmptyRecipes && recipes.more)
      }
    }

    blenderDataStorage.updateBlenderData(Object.values(tempResult))
  }

  return Object.freeze({
    getBlends,
    blenderData: blenderDataStorage.blenderData,
  })
}
