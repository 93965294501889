import styles from './styles.module.scss'
import ReactDOM from 'react-dom'
import React, { HTMLAttributes, useEffect, useState } from 'react'
import Button from 'components/Button'

/**
 * @callback onClose - triggers when the popup is closing
 */

/**
 * Define an html attribude interface and extend it
 * based on available props
 * The elements supports all html attributes
 * The popup is statefull and can be manage outside as well
 * @typedef Props
 * @prop {String} title - The title of popup component
 * @prop {Boolean} open - Determine the state of the popup, if it's opened or not
 * @prop {React.ReactElement} anchor - The anchor element of the popup, clicking on this element trigger the state of the popup (open/close)
 * @prop {onClose} - triggers when the popup is closing
 */
type Props = HTMLAttributes<HTMLDivElement> & {
  title: string
  open?: boolean
  callback: (value: boolean) => void
  successCallback: () => void
}

/**
 * @param {Props} props
 */
export const ConfirmationPopup: React.FC<Props> = ({
  children,
  title,
  open,
  className,
  callback,
  successCallback,
  ...props
}) => {
  const [opened, setOpened] = useState(false)
  useEffect(() => {
    if (open !== undefined) {
      setOpened(open)
    }
  }, [open])

  const onClick = (value: boolean) => {
    callback(value)
    setOpened(false)
    if (value === true) {
      successCallback()
    }
  }

  return (
    <>
      {ReactDOM.createPortal(
        opened && (
          <div className={styles['ui-popup-backdrop']}>
            <div {...props} className={styles['ui-popup']}>
              <header className={styles['ui-popup-header']}>{title && <h2>{title}</h2>}</header>

              <section>{children}</section>

              <div className={styles['ui-popup-actions']}>
                <Button bordered type="button" onClick={() => onClick(false)}>
                  Cancel
                </Button>
                <Button type="button" onClick={() => onClick(true)}>
                  Create blend
                </Button>
              </div>
            </div>
          </div>
        ),
        document.body,
      )}
    </>
  )
}
