import { useAssetsDataAPI } from 'services/api/assets'
import { useStore } from 'services/store'

export function useTemplatesLoader() {
  const store = useStore()
  const assetsAPI = useAssetsDataAPI(store.currentNetwork)
  async function loadCollectionTemplates(collectionName: string, setter: any) {
    const responseLimitLength = 100
    let page: number = 1
    let continueToFetch: boolean = true
    const templates = []
    // load all templates for current collection

    while (continueToFetch) {
      // @ts-ignore
      const { data } = await assetsAPI.getCollectionTemplates(page, collectionName)

      if (data.data) {
        templates.push(...data.data)
        page += 1

        if (data.data.length < responseLimitLength) {
          continueToFetch = false
        }
      } else {
        continueToFetch = false
      }
    }

    setter(templates)
  }

  return Object.freeze({ loadCollectionTemplates })
}
