import React, { createContext, useContext, useState } from 'react'

type Notification = Record<string, string>

type NotificationStore = {
  notifiesStack: Notification[]
  updateNotifiesStack: React.Dispatch<React.SetStateAction<Notification[]>>
}

const NotificationsContext = createContext<NotificationStore>({
  notifiesStack: [],
  updateNotifiesStack() {},
})

export const useNotificationsStore = () => useContext(NotificationsContext)

export const NotificationsProvider: React.FC = ({ children }) => {
  const [notifiesStack, setNotifiesStack] = useState<Notification[]>([])

  const value = {
    notifiesStack,
    updateNotifiesStack: setNotifiesStack,
  }

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>
}
