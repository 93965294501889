import {
  BlenderDataStorageService,
  UserAccountStorageService,
} from '../application/ports'
import { useStore } from './store'

export function useUserAccountStorage(): UserAccountStorageService {
  const { account, updateAccount } = useStore()

  return {
    userAccount: account,
    updateUserAccount: updateAccount,
  }
}

export function useBlenderDataStorage(): BlenderDataStorageService {
  const { blenderData, updateBlenderData } = useStore()

  return {
    blenderData,
    updateBlenderData,
  }
}
