import { memo, useMemo, useState, useEffect, useRef, useCallback } from 'react'

import { useOnClickOutside } from './useOnClickOutside'

import RenderOptions from './Options'

import { composeAtomicAssetImgUrl } from 'utils/composeAtomicAssetImgUrl'

import NoImage from 'assets/img/no-image.svg'

import styles from './styles.module.scss'

type SelectType = {
  callback: (value: number) => void
  selectedIndex: number
  position?: 'top' | 'bottom'
  filterTokens?: any
  supportedTokens: any[]
  isSearchAutoFocus?: boolean
}

const ITEMS_PER_VIEW = 60

const SelectComponent: React.FC<SelectType> = ({
  callback,
  selectedIndex = 0,
  position = 'bottom',
  filterTokens = [],
  supportedTokens,
  isSearchAutoFocus = false,
}) => {
  const ref: any = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [slicedOptions, setSlicedOptions] = useState<any[]>([])

  const toggling = () => setIsOpen(!isOpen)

  const getImage = (img: any) => (img ? composeAtomicAssetImgUrl(img) : NoImage)

  useOnClickOutside(ref, () => setIsOpen(false))

  const onOptionClicked = (value: number) => () => {
    setIsOpen(false)
    callback(value)
  }

  const maxPages = Math.ceil(supportedTokens.length / ITEMS_PER_VIEW)

  const selectedCurrencyData = supportedTokens.find((el: any) => {
    return selectedIndex === el.template_id
  })

  const memoedOptions = useMemo(
    () => supportedTokens.sort((a: any, b: any) => a?.name?.localeCompare(b.name)),
    [selectedIndex, supportedTokens],
  )

  const filteredOptions = useMemo(
    () =>
      Object.values(memoedOptions).filter((option) => {
        return option
      }),
    [filterTokens],
  )

  const handleScroll = useCallback(
    (event: any) => {
      if (currentPage - 1 === maxPages) return false
      if (slicedOptions.length === memoedOptions.length) return false
      if (isLoadingMore) return false
      if (event.target.scrollTop > event.target.scrollHeight * 0.85 && !isLoadingMore) {
        setIsLoadingMore(true)
        setCurrentPage((prevPage) => prevPage + 1)
      }
    },
    [slicedOptions],
  )

  useEffect(() => {
    const options = filteredOptions.slice(0, ITEMS_PER_VIEW * currentPage)
    setSlicedOptions(options)
    setIsLoadingMore(false)
  }, [currentPage, isOpen])

  return (
    <div
      ref={ref}
      className={`${styles['ui-select__wrapper']}
          ${isOpen ? styles['ui-select__wrapper--open'] : ''}
        `}>
      <div
        tabIndex={0}
        className={`${styles['ui-select']}
          ${isOpen ? styles['ui-select--open'] : ''}
          `}>
        <div className={styles['ui-select__selected']} onClick={toggling}>
          {selectedCurrencyData && (
            <>
              <img
                className={styles['ui-select__selected__image']}
                src={getImage(selectedCurrencyData.immutable_data.img)}
                alt=""
              />
              <div className={styles['ui-select__selected__text']}>
                {selectedCurrencyData.name} #{selectedCurrencyData.template_id}
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        {isOpen && (
          <RenderOptions
            options={slicedOptions}
            fullOptions={filteredOptions}
            position={position}
            handleScroll={handleScroll}
            onOptionClicked={onOptionClicked}
            isSearchAutoFocus={isSearchAutoFocus}
          />
        )}
      </div>
    </div>
  )
}

export const Select = memo(SelectComponent)
