import { useChangeNetworkService } from 'application/changeNetwork'
import { useStore } from 'services/store'

import styles from './Header.module.scss'

export const Header = () => {
  const storage = useStore()
  const networkService = useChangeNetworkService()
  return (
    <header className={styles['header']}>
      <div className={styles['header__wrapper']}>
        <span>Hello, {storage.account}!</span>

        <div className={styles['header__network']}>
          Current network:
          <div>
            {/* @ts-ignore */}
            <span
              onClick={() => networkService.changeNetwork('EOS')}
              style={{ opacity: storage.currentNetwork === 'EOS' ? '1' : '0.4' }}>
              EOS
            </span>
            {/* @ts-ignore */}
            <span
              onClick={() => networkService.changeNetwork('WAX')}
              style={{ opacity: storage.currentNetwork === 'WAX' ? '1' : '0.4' }}>
              WAX
            </span>
          </div>
        </div>
      </div>
    </header>
  )
}
