import Input from 'components/Form/Input'
import { Select } from 'components/Select'

import { getImage } from 'utils/composeAtomicAssetImgUrl'

import type { ITemplateIngredient } from 'domain/template'

import styles from './RecipeOutput.module.scss'

type Props = {
  /**
   * category of recipe
   */
  category: string
  /**
   * template is gotten after successful blend
   */
  outputTemplate: ITemplateIngredient | null
  selectedOutput: number
  plugins: string[]
  selectedPlugin: string
  updateCategory: (category: string) => void
  updatePlugin: (plugin: string) => void
  updateSelectedOutput: (output: number) => void

  selectSupportedTokens?: any
  description?: string
  updateDescription?: (description: string) => void
}

/**
 * ui for output's template
 */
export const RecipeOutput: React.FC<Props> = ({
  category,
  outputTemplate,
  selectedOutput,
  plugins,
  selectedPlugin,
  description,
  selectSupportedTokens = [],
  updateCategory,
  updatePlugin,
  updateSelectedOutput,
  updateDescription,
}) => {
  return (
    <div className={styles['recipe-output__box__preview']}>
      <h2>
        Output Template: {outputTemplate && outputTemplate['name']} #
        {outputTemplate && outputTemplate['template_id']}
      </h2>

      <div className={styles['recipe-output__output-container']}>
        <Select
          callback={updateSelectedOutput}
          selectedIndex={selectedOutput}
          supportedTokens={selectSupportedTokens}
          isSearchAutoFocus
        />
      </div>

      <div className={styles['recipe-output__available']}>
        Select plugins:{' '}
        {plugins.map((plugin) => (
          <button
            key={plugin}
            onClick={() => updatePlugin(plugin)}
            className={selectedPlugin === plugin
              ? styles['recipe-output__available--active']
              : ''
            }
          >
            {plugin}
          </button>
        ))}
        <button style={{ color: '#fff' }} onClick={() => updatePlugin('')}>
          Cancel
        </button>
      </div>

      <div className={styles['recipe-output__input-container']}>
        <Input
          mode="light"
          placeholder="Add recipe category"
          onChange={(e) => updateCategory(e.target.value)}
          value={category}
          type="text"
        />
      </div>
      {updateDescription && <div className={`
        ${styles['recipe-output__input-container']}
        ${styles['new-template__output-container-actions']}
      `}>
        <Input
          mode="light"
          placeholder="Add blend description"
          onChange={(e) => updateDescription(e.target.value)}
          value={description as string}
          type="text"
        />
      </div>}

      <div className={styles['recipe-output__box__preview-img']}>
        {outputTemplate && (
          <img src={getImage(outputTemplate['immutable_data']['img'])} alt="" />
        )}
      </div>
    </div>
  )
}
