import { Link } from 'react-location'
import styles from './styles.module.scss'
import WombatLogo from './assets/wombat.svg'
import Button from '../../components/Button'

export const NotFound = (): JSX.Element => {
  return (
    <main className={styles['notFound']}>
      <h1 className={styles['notFound__title']}>404</h1>
      <img className={styles['notFound__logo']} src={WombatLogo} alt="logo" />
      <p className={styles['notFound__subtitle']}>Page not found</p>

      <div className={styles['notFound__button-container']}>
        <Link to="/">
          <Button className={styles['notFound__button']} type="button">
            To Home page
          </Button>
        </Link>
      </div>
    </main>
  )
}
